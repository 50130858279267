.card_wrapper {
    
    margin: 0rem 0rem 2rem 0rem;

    display:flex;
    justify-content: center;
}


.card {
    min-width: 240px;
    margin: 2vw 2vw 1vw 2vw
}
.project_view {
    color: var(--color-4);
    min-height:100vh;
    
    width:100vw;
    position: relative;
    left:0;
    top:0;

}
.project_title {
    margin: 2rem auto 2rem auto;
    color: azure;
}
.game_title {

    margin: 2rem auto 0rem auto;
    font-family:'Times New Roman', Times, serif; 
    color: var(--color-1);
}

.game_view {

    display:flex;
    align-items: center;
    padding-left: 7rem;
    padding-right: 7rem;

}
.Board {
    position: relative;  
    margin: 5vh auto 3vh auto;  
    background-color: var(--color-1);
    border:2px solid;
    border-color: var(--color-3);
    border-radius: 5px;
    background-image:    
    linear-gradient(var(--color-3) 1px, transparent 1px),    
    linear-gradient(90deg,var(--color-3) 1px, transparent 1px);
    max-width: 100vw;

}
.Cell {  
    background: #ccc;  
    position: absolute;
}
.control_holder {
    margin: 0 3vw 0 3vw;
}
.controls {
    font-family: 'Times New Roman', Times, serif;
    background-color: var(--color-1);
    padding: .5rem 1rem 1rem 1rem;
    border-radius: 10px;
    position:relative;
    margin-left: auto;
    margin-right: auto;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.control_item {
    margin: 3px;
    padding: 3px;
}

.description {
    background-color: var(--color-1);
    color: var(--color-3);
    padding: 1rem .5rem 1rem .5rem;
    border-radius: 20px;
    text-align: center;
    max-width: 20rem;
    margin-top: 4rem;
}



.table_header {
    border-radius: 5px 5px 0px 0px;
    padding: 8px 8px 8px 8px;
    display:flex;
    flex-direction:row;
    background-color: aliceblue;
 
}
.header_child1 {
    width: 20vw;
    margin-bottom:0;
    border-right: 2px solid var(--color-1);
    font-size: 3vw
}
.header_child2 {
    width:40vw;
    margin-left:1rem;
    margin-bottom:0;
    font-size: 3vw;
}
.no_margin_bottom {
    margin-bottom:0;
}
.tabel_label {

    cursor: pointer;
    padding: 0px 0px 0px 0px;
    display:flex;

    
    border-right: 2px solid azure;

    flex-direction: column;
    max-width: 20vw;
    min-width: 20vw;
}

.table_text {
    color: var(--color-3);
    padding: 1rem;

}
.tabel_content {
    padding: 8px 8px 8px 8px;
    display:flex;
    flex-direction: row;
}
.my_table {

    display: flex;
    flex-direction: column;
    margin-left: 10vw;
    margin-right: 10vw;
    border-radius: 5px;
    color: var(--color-3);
    background-color: var(--color-1);
}

.clust_desc_wrapper {
    padding: 0vw 25vw 0vw 25vw;
    justify-content: center;
}
.clust_desc_wrapper > * {
    color:azure;
    margin-bottom: 1rem;
}

@media (min-width:801px) {
    .header_child1 {
        
        font-size: 1.563rem;
    }
    .header_child2 {
        
        font-size: 1.563rem;
    }

}
@media (max-width:800px) {
    .card_wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .card {
        margin: 3em
    }
    .game_view {
        flex-direction: column;
    }
    .clust_desc_wrapper {
        padding-left:1rem;
        padding-right:1rem;
    }
    .clust_desc_wrapper::after {
        width:100vw;
    }
    .link_box {
        position: relative;
        right: 5vw;
    }
    .hover_text {
        font-size: 3vw
    }
    .algo_info {
        font-size: .7rem;
    }
}
.link_text  {
    
    color: var(--color-4);

}


.hover_text:hover {
    color: var(--color-5);
}
.home_button_proj {
    cursor: pointer;
    color: var(--color-3);
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.25rem 0.5rem;
    text-shadow: var(--shadow-1);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
    background-color: aliceblue;
  }
.home_button_proj:hover {
    color: --grey-400;
    background-color: var(--color-1);
    
}
