

.main {

    background-image: url("../photos/background.jpeg");
    min-height: 100vh;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   
    box-shadow: inset 0 0 0 2000px rgba(66, 66, 66, 0.4); 
}

.main_item {
    
    padding:5rem 0rem;

    cursor: default;
    color: var(--white);
    background: clear;
    border: transparent;
    text-shadow: black;
    text-align: center;

    
}
.summary {

    max-width: 1000px;
}
 
.block {

    background-color: black;
    height: 15rem;
    box-shadow: inset 0 0 0 2000px rgba(66, 66, 66, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.headshot {   
    cursor: pointer;
    height: 12rem;
    margin: 0 20vw 0 20vw;
}
.headshot:hover {
    height: 17rem;
}


.main2 {
    min-height: 100vh;
    background-image: url("../photos/background2.jpeg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    box-shadow: inset 0 0 0 2000px rgba(66, 66, 66, 0.4);

}
.nav_bar {
    padding: 0;
}
.summary {

    padding: 0 0 2vw 0;
}
.nav_btn {
    cursor: pointer;
    color: var(--white);
    background: clear;
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    text-shadow: var(--shadow-1);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
  }

.about_title {

    padding-top:2rem;
    cursor: default;
    color: var(--white);
    background: clear;
    border: transparent;
    text-shadow: black;
    text-align: center;
}

.about_text{
   
    padding:3rem auto;
    padding-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 50vw;
    cursor: default;
    color: var(--white);
    background: clear;
    border: transparent;
    text-shadow: black;
}
@media (max-width:800px) {
    .about_text {
        padding:0rem auto;
        max-width: 100vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    .main {
        min-width: 100vw;
        min-height: 100vh;
    };
}

.about_text > * {

    margin-top: 1rem;

}
  .nav_btn:hover {
    background: clear;
    color: --grey-400;
    text-shadow: 2px 2px 5px black,
                 0px 0px 7px black;
    
}
  