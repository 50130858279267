.contact_title {
    padding-top: 2rem;
    color: white
}
.contact_card {    
    display: flex;
    flex-direction: column;
    margin: 10vh auto 10vh auto;
    padding: 1rem;
    border-radius: 1rem;
    width: fit-content;
    height: fit-content;
    background-color: white;
}
.contact_background{
    background-image: url("../photos/background.jpeg");
    min-height: 100vh;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    box-shadow: inset 0 0 0 2000px rgba(66, 66, 66, 0.4); 
}
.contact_row {
    display: flex;
    flex-direction: row;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    align-items: center;
    
}
.clickable_link {
    color: black;
    
}
.logo {
    object-fit:cover;
    height: 15px;
    margin-right: 1rem;
    width: 15px;
}
.home_button {
    cursor: pointer;
    color: white;
    background: clear;
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    text-shadow: var(--shadow-1);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
  }
.home_button:hover {
    background: clear;
    color: --grey-400;
    text-shadow: 2px 2px 5px black,
                 0px 0px 7px black;
    
}
